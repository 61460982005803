<template>
  <div class="programme-center">
    <div>
      <div class="programme-left" v-for="(ite,index) in item">
        <div v-if="index == number">
          <div class="plan-title">
            <div style="color: #131313;width: 55px" >
              方案{{index+1}}：
            </div>
            <div class="plan-title-center">
              {{ite.name}}<br>
              <span>{{ title }}：{{ite.totalCost}} </span> <span>自付费用：{{ite.selfPay}}</span>
            </div>
          </div>
          <div v-if="ite.children">
            <div class="drugs-list" v-for="it in ite.children">
              <img :src="it.url">
              药品名称：{{it.drugs}}<br>
              医保类型：{{it.medical}}<br>
              疗程：{{it.course}}<br>
              参考单价：{{it.price}}<br>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="programme-right" @click="onReplay(item)"> <van-icon name="replay" color="#1675FF" size="20px" /></div>
  </div>
</template>

<script>

import anaquzuopian from '../../assets/images/drugs/anaquzuopian.png'
import duoxitasai from '../../assets/images/drugs/duoxitasai.png'
import biaoroubixing from '../../assets/images/drugs/biaoroubixing.png'
import enmeiqutuozhudankang from '../../assets/images/drugs/enmeiqutuozhudankang.png'
import fuweisiqunzhusheye from '../../assets/images/drugs/fuweisiqunzhusheye.png'
import juyuansuantamoxifenpian from '../../assets/images/drugs/juyuansuantamoxifenpian.png'
import huanlinxian from '../../assets/images/drugs/huanlinxian.png'
import jiaqiangyuntong from '../../assets/images/drugs/jiaqiangyuntong.png'
import patuozhudankang from '../../assets/images/drugs/patuozhudankang.png'
import paibaixilijiaonang from '../../assets/images/drugs/paibaixilijiaonang.png'
import xidanbenan from '../../assets/images/drugs/xidanbenan.png'
import kabo from '../../assets/images/drugs/kabo.png'
import liangbingruilin from '../../assets/images/drugs/liangbingruilin.png'
import qutuokangzhu from '../../assets/images/drugs/qutuokangzhu.png'
import dabaibaizishanchun from '../../assets/images/drugs/dabaibaizishanchun.png'
import zhanshanchunzhusheye from '../../assets/images/drugs/zhanshanchunzhusheye.png'


export default {
  name: 'tubercle',
  data() {
    return {
      number:0
    }
  },
  props: {
    item:{
      type:Array
    },
    title:{
      type:String
    }

  },
  components: {

  },
  created() {

  },
  mounted() {

  },
  methods: {
    onReplay(){
      if(this.number == this.item.length-1){
        this.number = 0
      }else {
        this.number++
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.programme-center{
  margin-top: .3rem;
}
.plan-title{
  display: flex;
  font-size: .24rem;

}
.plan-title-center{
  color: #FF1B16;
  span{
    color: #666666;
    margin-right: .3rem;
    margin-top: .19rem;
  }
}
.drugs-list{
  margin-top: .3rem;
  img{
    width: 2.05rem;
    height: 1.48rem;
    float: left;
    margin-right: .36rem;
    border-radius: .1rem;
    border: 1px solid rgba(146, 157, 163, 0.2);
  }
  font-size: .24rem;
  color: #131313;
  line-height: .45rem;
}
.programme-center{
  display: flex;
}

</style>

