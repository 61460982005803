var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "knowledge" }, [
    _c("div", { staticClass: "bottom" }, [
      _vm._m(0),
      _c("div", { staticClass: "knowledge-box" }, [
        _c("div", { staticClass: "title" }, [
          _vm._v(" 预估主要治疗药物和费用 ")
        ]),
        _c("div", { staticClass: "tijian" }, [
          _vm._v("针对" + _vm._s(_vm.getBIRADS(_vm.BIRADS)) + "类的患者")
        ]),
        _vm._m(1),
        _c("div", { staticClass: "yuhou" }, [
          _c("div", { staticClass: "tips" }, [_vm._v("关于预后：")]),
          _c("div", { staticClass: "center" }, [
            _vm._v(
              " 乳腺结节有良恶性之分，BI-RADS 4类及以上的乳腺结节，需要病理检查结果进行判断。 "
            )
          ]),
          _c("div", { staticClass: "list-box" }, [
            _c("div", { staticClass: "tips" }, [
              _vm._v(
                "根据" +
                  _vm._s(_vm.getBIRADS(_vm.BIRADS)) +
                  "类，乳腺结节恶化的可能性为："
              )
            ]),
            _c("div", { staticClass: "list" }, [
              _c("ul", [
                _vm.BIRADS == "0级"
                  ? _c("li", [
                      _c("span", [_vm._v("BI-RADS 0：")]),
                      _vm._v("评估不完全，需要补充其他影像学检查进一步评估。")
                    ])
                  : _vm._e(),
                _vm.BIRADS == "1级"
                  ? _c("li", [
                      _c("span", [_vm._v("BI-RADS 1：")]),
                      _vm._v("阴性，未见异常。")
                    ])
                  : _vm._e(),
                _vm.BIRADS == "2级"
                  ? _c("li", [
                      _c("span", [_vm._v("BI-RADS 2：")]),
                      _vm._v(
                        "良性病灶，基本上可以排除恶性病变，根据年龄及临床表现可行6-12个月随诊。"
                      )
                    ])
                  : _vm._e(),
                _vm.BIRADS == "3级"
                  ? _c("li", [
                      _c("span", [_vm._v("BI-RADS 3：")]),
                      _vm._v(
                        "可能良性病灶，恶性危险性应该小于2%，建议短期（3-6个月）复查及其他进一步检查。"
                      )
                    ])
                  : _vm._e(),
                _vm.BIRADS == "4a级"
                  ? _c("li", [
                      _vm._v(" BI-RADS 4a ：更倾向于良性可能，恶性率＜10%； ")
                    ])
                  : _vm._e(),
                _vm.BIRADS == "4b级"
                  ? _c("li", [
                      _vm._v(" BI-RADS 4b ：难以明确良恶性，恶性率＜50%；"),
                      _c("br")
                    ])
                  : _vm._e(),
                _vm.BIRADS == "4c级"
                  ? _c("li", [
                      _vm._v(" BI-RADS 4c ：恶性可能性较高，恶性率＞50%； ")
                    ])
                  : _vm._e(),
                _vm.BIRADS == "5级"
                  ? _c("li", [
                      _c("span", [_vm._v(" BI-RADS 5：")]),
                      _vm._v("高度可能恶性，应积极采取适当的诊断及处理措施；")
                    ])
                  : _vm._e(),
                _vm.BIRADS == "6级"
                  ? _c("li", [
                      _c("span", [_vm._v(" BI-RADS 6：")]),
                      _vm._v("已经活检证实为恶性。")
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
      ])
    ]),
    _c("div", { staticClass: "bottom" }, [
      _vm._m(2),
      _c("div", { staticClass: "knowledge-box" }, [
        _c(
          "div",
          { staticClass: "title" },
          [
            _vm._v(" 预估疾病进展及未来主要治疗药物和费用 "),
            _c("van-icon", {
              attrs: { name: "info", color: "#1675FF", size: "16px" },
              on: {
                click: function($event) {
                  _vm.show = true
                }
              }
            })
          ],
          1
        ),
        _c("div", { staticClass: "center" }, [
          _c("div", { staticClass: "programme-table" }, [
            _c("div", { staticClass: "programme-top" }, [
              _c(
                "div",
                {
                  class: { active: _vm.active == "1" },
                  on: {
                    click: function($event) {
                      return _vm.onchange("1")
                    }
                  }
                },
                [_vm._v("新辅助治疗")]
              ),
              _c(
                "div",
                {
                  class: { active: _vm.active == "2" },
                  on: {
                    click: function($event) {
                      return _vm.onchange("2")
                    }
                  }
                },
                [_vm._v("手术治疗")]
              ),
              _c(
                "div",
                {
                  class: { active: _vm.active == "3" },
                  on: {
                    click: function($event) {
                      return _vm.onchange("3")
                    }
                  }
                },
                [_vm._v("术后辅助治疗")]
              ),
              _c(
                "div",
                {
                  class: { active: _vm.active == "4" },
                  on: {
                    click: function($event) {
                      return _vm.onchange("4")
                    }
                  }
                },
                [_vm._v("术后放射治疗")]
              )
            ]),
            _c("div", { staticClass: "programme-bot" }, [
              _vm.active === "1"
                ? _c(
                    "div",
                    _vm._l(_vm.xinfuzhu, function(item) {
                      return _c(
                        "div",
                        { staticClass: "programme-box" },
                        [
                          _c("div", { staticClass: "programme-box-title" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/programme.png")
                              }
                            }),
                            _c("p", [_vm._v(_vm._s(item.name))]),
                            _c("span", [
                              _vm._v("费用合计：" + _vm._s(item.totalCost))
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": ".2rem" } },
                              [_vm._v("自付费用：" + _vm._s(item.selfPay))]
                            )
                          ]),
                          _c("programme", {
                            attrs: { item: item.children, title: "费用合计" }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.active === "2"
                ? _c(
                    "div",
                    _vm._l(_vm.operation, function(item) {
                      return _c(
                        "div",
                        { staticClass: "programme-box" },
                        [
                          _c("div", { staticClass: "programme-box-title" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/programme.png")
                              }
                            }),
                            _c("p", [_vm._v(_vm._s(item.name))]),
                            _c("span", [
                              _vm._v("费用合计：" + _vm._s(item.totalCost))
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": ".2rem" } },
                              [_vm._v("自付费用：" + _vm._s(item.selfPay))]
                            )
                          ]),
                          _c("programme", {
                            attrs: { item: item.children, title: "费用合计" }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.active === "3"
                ? _c(
                    "div",
                    _vm._l(_vm.shuhouxinfuzhu, function(item) {
                      return _c(
                        "div",
                        { staticClass: "programme-box" },
                        [
                          _c("div", { staticClass: "programme-box-title" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/programme.png")
                              }
                            }),
                            _c("p", [_vm._v(_vm._s(item.name))]),
                            _c("span", [
                              _vm._v("费用合计：" + _vm._s(item.totalCost))
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": ".2rem" } },
                              [_vm._v("自付费用：" + _vm._s(item.selfPay))]
                            )
                          ]),
                          _c("programme", {
                            attrs: { item: item.children, title: "费用合计" }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm.active === "4"
                ? _c(
                    "div",
                    _vm._l(_vm.shuhoufangliao, function(item) {
                      return _c(
                        "div",
                        { staticClass: "programme-box" },
                        [
                          _c("div", { staticClass: "programme-box-title" }, [
                            _c("img", {
                              attrs: {
                                src: require("../../assets/images/programme.png")
                              }
                            }),
                            _c("p", [_vm._v(_vm._s(item.name))]),
                            _c("span", [
                              _vm._v("单次费用：" + _vm._s(item.totalCost))
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": ".2rem" } },
                              [_vm._v("自付费用：" + _vm._s(item.selfPay))]
                            )
                          ]),
                          _c("programme", {
                            attrs: { item: item.children, title: "单次费用" }
                          })
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e()
            ])
          ])
        ])
      ])
    ]),
    _vm.show
      ? _c("div", { staticClass: "layer-box" }, [
          _vm._m(3),
          _c(
            "div",
            {
              staticStyle: { "text-align": "center" },
              on: {
                click: function($event) {
                  _vm.show = false
                }
              }
            },
            [
              _c("van-icon", {
                attrs: { name: "close", color: "#ffffff", size: "25px" }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wenti-img" }, [
      _c("img", { attrs: { src: require("../../assets/images/xjqr.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mianze" }, [
      _c("div", { staticClass: "tips" }, [_vm._v("免责条款：")]),
      _c("div", { staticStyle: { "line-height": ".4rem" } }, [
        _vm._v(
          " 如下治疗方案及药物等信息来源于国家CSCO乳腺癌诊疗指南2021，但仅作为预估治疗费用的依据，不得作为患者诊疗方案的建议。本平台不承担诊疗行为相关的任何责任。 "
        )
      ])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "wenti-img" }, [
      _c("img", { attrs: { src: require("../../assets/images/xjqr.png") } })
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "layer" }, [
      _vm._v(
        " 1：本方案根据分子分型，仅进行I级推荐方案（无I级推荐方案，则给予II级推荐方案）。"
      ),
      _c("br"),
      _vm._v(
        " 2：同方案中同一类型药品涉及不同品种、不同厂家，费用均有差异，根据优选纳入医保及药物可及性，进行药品通用名价格参考。"
      ),
      _c("br"),
      _vm._v(
        " 3：不同方案治疗周期依据指南推荐为基准费用参考，实际诊疗过程中根据患者情况，医生医嘱调整均有差异。"
      ),
      _c("br"),
      _vm._v(
        " 4：医保报销比例因药物种类、限定适应症、参保类型、地方报销政策均有差异，目前医保乙类药品费用，按20%绝对自付，乙类医保内及甲类药品按平均报销水平30%自付进行计算，作为参考。"
      ),
      _c("br"),
      _vm._v(" 5：如需选择原研药或自费药物，价格均有明显增加。"),
      _c("br"),
      _vm._v(
        " 6：内分泌治疗因计算费用周期，未计算内分泌后续的强化治疗方案及费用。"
      ),
      _c("br"),
      _vm._v(
        " 7：因肿瘤用药根据体表面积或体重相关，目前以身高168cm，体重55kg，作为基准参考测算。"
      ),
      _c("br"),
      _vm._v(" 8：放疗费用仅按单次计算，根据部位、疗程等次数差异极大。"),
      _c("br")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }