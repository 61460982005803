var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "programme-center" }, [
    _c(
      "div",
      _vm._l(_vm.item, function(ite, index) {
        return _c("div", { staticClass: "programme-left" }, [
          index == _vm.number
            ? _c("div", [
                _c("div", { staticClass: "plan-title" }, [
                  _c(
                    "div",
                    { staticStyle: { color: "#131313", width: "55px" } },
                    [_vm._v(" 方案" + _vm._s(index + 1) + "： ")]
                  ),
                  _c("div", { staticClass: "plan-title-center" }, [
                    _vm._v(" " + _vm._s(ite.name)),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.title) + "：" + _vm._s(ite.totalCost) + " "
                      )
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v("自付费用：" + _vm._s(ite.selfPay))])
                  ])
                ]),
                ite.children
                  ? _c(
                      "div",
                      _vm._l(ite.children, function(it) {
                        return _c("div", { staticClass: "drugs-list" }, [
                          _c("img", { attrs: { src: it.url } }),
                          _vm._v(" 药品名称：" + _vm._s(it.drugs)),
                          _c("br"),
                          _vm._v(" 医保类型：" + _vm._s(it.medical)),
                          _c("br"),
                          _vm._v(" 疗程：" + _vm._s(it.course)),
                          _c("br"),
                          _vm._v(" 参考单价：" + _vm._s(it.price)),
                          _c("br")
                        ])
                      }),
                      0
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ])
      }),
      0
    ),
    _c(
      "div",
      {
        staticClass: "programme-right",
        on: {
          click: function($event) {
            return _vm.onReplay(_vm.item)
          }
        }
      },
      [
        _c("van-icon", {
          attrs: { name: "replay", color: "#1675FF", size: "20px" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }