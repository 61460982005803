<template>
  <div class="knowledge">
    <div class="bottom" >
      <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
      <div class="knowledge-box">
        <div class="title">
          预估主要治疗药物和费用
        </div>
        <div class="tijian" >针对{{getBIRADS(BIRADS)}}类的患者</div>
        <div class="mianze">
          <div class="tips">免责条款：</div>
          <div style="line-height: .4rem">
            如下治疗方案及药物等信息来源于国家CSCO乳腺癌诊疗指南2021，但仅作为预估治疗费用的依据，不得作为患者诊疗方案的建议。本平台不承担诊疗行为相关的任何责任。
          </div>
        </div>
        <div class="yuhou">
          <div class="tips">关于预后：</div>
          <div class="center">
            乳腺结节有良恶性之分，BI-RADS 4类及以上的乳腺结节，需要病理检查结果进行判断。
          </div>
          <div class="list-box">
            <div class="tips">根据{{getBIRADS(BIRADS)}}类，乳腺结节恶化的可能性为：</div>
            <div class="list">
              <ul>
                <li v-if="BIRADS=='0级'"><span>BI-RADS 0：</span>评估不完全，需要补充其他影像学检查进一步评估。</li>
                <li v-if="BIRADS=='1级'"><span>BI-RADS 1：</span>阴性，未见异常。</li>
                <li v-if="BIRADS=='2级'"><span>BI-RADS 2：</span>良性病灶，基本上可以排除恶性病变，根据年龄及临床表现可行6-12个月随诊。</li>
                <li v-if="BIRADS=='3级'"><span>BI-RADS 3：</span>可能良性病灶，恶性危险性应该小于2%，建议短期（3-6个月）复查及其他进一步检查。</li>
                <li v-if="BIRADS=='4a级'">
                  BI-RADS 4a ：更倾向于良性可能，恶性率＜10%；
                </li>
                <li v-if="BIRADS=='4b级'">
                  BI-RADS 4b ：难以明确良恶性，恶性率＜50%；<br>
                </li>
                <li v-if="BIRADS=='4c级'">
                  BI-RADS 4c ：恶性可能性较高，恶性率＞50%；
                </li>
                <li v-if="BIRADS=='5级'"><span> BI-RADS 5：</span>高度可能恶性，应积极采取适当的诊断及处理措施；</li>
                <li v-if="BIRADS=='6级'"><span> BI-RADS 6：</span>已经活检证实为恶性。</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" >
      <div class="wenti-img"><img src="../../assets/images/xjqr.png"></div>
      <div class="knowledge-box">
        <div class="title">
          预估疾病进展及未来主要治疗药物和费用 <van-icon @click="show=true" name="info" color="#1675FF" size="16px" />
        </div>
        <div class="center" >
          <div class="programme-table">
            <div class="programme-top">
              <div :class="{active : active == '1'}" @click="onchange('1')">新辅助治疗</div>
              <div :class="{active : active == '2'}" @click="onchange('2')">手术治疗</div>
              <div :class="{active : active == '3'}" @click="onchange('3')">术后辅助治疗</div>
              <div :class="{active : active == '4'}" @click="onchange('4')">术后放射治疗</div>
            </div>
            <div class="programme-bot">
              <div v-if="active === '1'">
                <div class="programme-box" v-for="item in xinfuzhu">
                  <div class="programme-box-title">
                    <img src="../../assets/images/programme.png">
                    <p>{{item.name}}</p>
                    <span>费用合计：{{item.totalCost}}</span><span style="margin-left: .2rem">自付费用：{{item.selfPay}}</span>
                  </div>
                  <programme :item="item.children"  title="费用合计"></programme>
                </div>
              </div>
              <div v-if="active === '2'">
                <div class="programme-box" v-for="item in operation">
                  <div class="programme-box-title">
                    <img src="../../assets/images/programme.png">
                    <p>{{item.name}}</p>
                    <span>费用合计：{{item.totalCost}}</span><span style="margin-left: .2rem">自付费用：{{item.selfPay}}</span>
                  </div>
                  <programme :item="item.children"  title="费用合计"></programme>
                </div>
              </div>
              <div v-if="active === '3'">
                <div class="programme-box" v-for="item in shuhouxinfuzhu">
                  <div class="programme-box-title">
                    <img src="../../assets/images/programme.png">
                    <p>{{item.name}}</p>
                    <span>费用合计：{{item.totalCost}}</span><span style="margin-left: .2rem">自付费用：{{item.selfPay}}</span>
                  </div>
                  <programme :item="item.children" title="费用合计"></programme>
                </div>
              </div>
              <div v-if="active === '4'">
                <div class="programme-box" v-for="item in shuhoufangliao">
                  <div class="programme-box-title">
                    <img src="../../assets/images/programme.png">
                    <p>{{item.name}}</p>
                    <span>单次费用：{{item.totalCost}}</span><span style="margin-left: .2rem">自付费用：{{item.selfPay}}</span>
                  </div>
                  <programme :item="item.children" title="单次费用"></programme>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layer-box" v-if="show">
      <div class="layer">
        1：本方案根据分子分型，仅进行I级推荐方案（无I级推荐方案，则给予II级推荐方案）。<br>
        2：同方案中同一类型药品涉及不同品种、不同厂家，费用均有差异，根据优选纳入医保及药物可及性，进行药品通用名价格参考。<br>
        3：不同方案治疗周期依据指南推荐为基准费用参考，实际诊疗过程中根据患者情况，医生医嘱调整均有差异。<br>
        4：医保报销比例因药物种类、限定适应症、参保类型、地方报销政策均有差异，目前医保乙类药品费用，按20%绝对自付，乙类医保内及甲类药品按平均报销水平30%自付进行计算，作为参考。<br>
        5：如需选择原研药或自费药物，价格均有明显增加。<br>
        6：内分泌治疗因计算费用周期，未计算内分泌后续的强化治疗方案及费用。<br>
        7：因肿瘤用药根据体表面积或体重相关，目前以身高168cm，体重55kg，作为基准参考测算。<br>
        8：放疗费用仅按单次计算，根据部位、疗程等次数差异极大。<br>
      </div>
      <div style="text-align: center" @click="show=false"><van-icon name="close" color="#ffffff" size="25px" /></div>
    </div>
  </div>
</template>

<script>

import anaquzuopian from '../../assets/images/drugs/anaquzuopian.png'
import duoxitasai from '../../assets/images/drugs/duoxitasai.png'
import biaoroubixing from '../../assets/images/drugs/biaoroubixing.png'
import enmeiqutuozhudankang from '../../assets/images/drugs/enmeiqutuozhudankang.png'
import fuweisiqunzhusheye from '../../assets/images/drugs/fuweisiqunzhusheye.png'
import juyuansuantamoxifenpian from '../../assets/images/drugs/juyuansuantamoxifenpian.png'
import huanlinxian from '../../assets/images/drugs/huanlinxian.png'
import jiaqiangyuntong from '../../assets/images/drugs/jiaqiangyuntong.png'
import patuozhudankang from '../../assets/images/drugs/patuozhudankang.png'
import paibaixilijiaonang from '../../assets/images/drugs/paibaixilijiaonang.png'
import xidanbenan from '../../assets/images/drugs/xidanbenan.png'
import kabo from '../../assets/images/drugs/kabo.png'
import liangbingruilin from '../../assets/images/drugs/liangbingruilin.png'
import qutuokangzhu from '../../assets/images/drugs/qutuokangzhu.png'
import dabaibaizishanchun from '../../assets/images/drugs/dabaibaizishanchun.png'
import zhanshanchunzhusheye from '../../assets/images/drugs/zhanshanchunzhusheye.png'
import duoroubixing from '../../assets/images/drugs/duoroubixing.png'
import laiquzupian from '../../assets/images/drugs/laiquzupian.png'
import yiximeitanpian from '../../assets/images/drugs/yiximeitanpian.png'
import gesheruilin from '../../assets/images/drugs/gesheruilin.png'

import programme from "./tubercle-module";
import {sessionState} from "../../api";
export default {
  name: 'tubercle',
  data() {
    return {
      show:false,
      BIRADS:"",
      xinfuzhu:[
       {
         code:"01",
         name:"HER2阳性乳腺癌术前新辅助治疗",
         totalCost:"8万-8.8万",
         selfPay:"4.3万-4.7万",
         children:[
           {
             name:"多西他赛+卡铂+曲妥珠单抗+帕妥珠单抗",
             totalCost:"80120.00",
             selfPay:"43239.80",
             children:[
                 {
                   url:duoxitasai,
                   drugs:"多西他赛",
                   course:"1/21d*6",
                   price:"995.00",
                   medical:"乙类"
                 },
               {
                 url:kabo,
                 drugs:"卡铂",
                 course:"1/21d*6",
                 price:"95.00",
                 medical:"甲类"
               },
               {
                 url:qutuokangzhu,
                 drugs:"曲妥珠单抗",
                 course:"1/21d*6",
                 price:"3180.00",
                 medical:"乙类"
               },
               {
                 url:patuozhudankang,
                 drugs:"帕妥珠单抗",
                 course:"1/21d*6",
                 price:"2180.00",
                 medical:"丙类"
               },
             ]
           },
           {
             name:"紫杉类+曲妥珠单抗+帕妥珠单抗",
             totalCost:"88070.00",
             selfPay:"47296.40",
             children:[
               {
                 url:duoxitasai,
                 drugs:"多西他赛",
                 course:"1/21d*6",
                 price:"995.00",
                 medical:"乙类"
               },
               {
                 url:qutuokangzhu,
                 drugs:"曲妥珠单抗",
                 course:"1/21d*6",
                 price:"3180.00",
                 medical:"乙类"
               },
               {
                 url:patuozhudankang,
                 drugs:"帕妥珠单抗",
                 course:"1/21d*6",
                 price:"2180.00",
                 medical:"丙类"
               },
             ]
           }
         ]
       },
        {
          code:"02",
          name:"三阴性乳腺癌术前新辅助治疗",
          totalCost:"3.3万-5.3万",
          selfPay:"1.4万-2.3万",
          children:[
            {
              name:"紫杉类+蒽环类+环磷酰胺",
              totalCost:"48486.00",
              selfPay:"20396.40",
              children:[
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*6",
                  price:"995.00",
                  medical:"乙类"
                },
                {
                  url:duoroubixing,
                  drugs:"多柔比星",
                  course:"1/21d*6",
                  price:"34.00",
                  medical:"甲类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*6",
                  price:"90.00",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"蒽环类+紫杉类",
              totalCost:"53880.00",
              selfPay:"23707.20",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*6",
                  price:"155.00 ",
                  medical:"乙类"
                },
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*6",
                  price:"995.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"紫杉类+铂类",
              totalCost:"33906.00",
              selfPay:"14360.04",
              children:[
                {
                  url:dabaibaizishanchun,
                  drugs:"白蛋白紫杉醇",
                  course:"1/21d*6",
                  price:"831.00",
                  medical:"乙类"
                },
                {
                  url:kabo,
                  drugs:"卡铂",
                  course:"1/21d*6",
                  price:"95.00",
                  medical:"甲类"
                },
              ]
            }
          ]
        },
        {
          code:"03",
          name:"激素受体阳性乳腺癌术前新辅助化疗",
          totalCost:"4.8万-5.3万",
          selfPay:"2万-2.3万",
          children:[
            {
              name:"紫杉类+蒽环类+环磷酰胺",
              totalCost:"48486.00",
              selfPay:"20396.40",
              children:[
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*6",
                  price:"995.00",
                  medical:"乙类"
                },
                {
                  url:duoroubixing,
                  drugs:"多柔比星",
                  course:"1/21d*6",
                  price:"34.00",
                  medical:"甲类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*6",
                  price:"90.00",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"蒽环类+紫杉类",
              totalCost:"53880.00",
              selfPay:"23707.20",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*6",
                  price:"155.00 ",
                  medical:"乙类"
                },
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*6",
                  price:"995.00",
                  medical:"乙类"
                },
              ]
            },
          ]
        },
        {
          code:"04",
          name:"激素受体阳性乳腺癌术前内分泌治疗",
          totalCost:"599元-5331元",
          selfPay:"263元-2346元",
          children:[
            {
              name:"芳香化酶抑制剂",
              totalCost:"1139.40",
              selfPay:"501.34",
              children:[
                {
                  url:anaquzuopian,
                  course:"qd*30*6",
                  drugs:"阿那曲唑片",
                  price:"6.33",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"芳香化酶抑制剂",
              totalCost:"2340.00",
              selfPay:"1029.60",
              children:[
                {
                  url:yiximeitanpian,
                  course:"qd*30*6",
                  drugs:"依西美坦片",
                  price:"13.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"芳香化酶抑制剂",
              totalCost:"599.40",
              selfPay:"263.74",
              children:[
                {
                  url:laiquzupian,
                  course:"qd*30*6",
                  drugs:"来曲唑片",
                  price:"3.33",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"亮丙瑞林+AI",
              totalCost:"5331.40",
              selfPay:"2345.82",
              children:[
                {
                  url:liangbingruilin,
                  course:"1/28*6",
                  drugs:"亮丙瑞林",
                  price:"1183.00",
                  medical:"乙类"
                },
                {
                  url:laiquzupian,
                  course:"qd*30*6",
                  drugs:"来曲唑片",
                  price:"3.33",
                  medical:"乙类"
                },
              ]
            },
          ]
        }
      ],
      operation:[
        {
          code:"01",
          name:"乳腺癌保乳切除术",
          totalCost:"1.7万-2.7万",
          selfPay:"5100元-8100元",
          children:[
            {
              name:"单侧乳腺癌保乳手术+前哨淋巴结活检术",
              totalCost:"17000.00-20000.00",
              selfPay:"5100~6000",
            },
            {
              name:"双侧乳腺癌保乳手术+前哨淋巴结活检术",
              totalCost:"22000.00-27000.00",
              selfPay:"6600~8100",
            },
            {
              name:"单侧乳腺癌保乳手术+前哨淋巴结活检术+腋窝淋巴结清扫术",
              totalCost:"18000.00-21000.00",
              selfPay:"5400~6300",
            },
            {
              name:"双侧乳腺癌保乳手术+前哨淋巴结活检术+腋窝淋巴结清扫术",
              totalCost:"23000.00-27000.00",
              selfPay:"6900~8100",
            }
          ]
        },
        {
          code:"01",
          name:"乳腺癌全切术",
          totalCost:"1.6万-3.1万",
          selfPay:"4800元-9300元",
          children:[
            {
              name:"单侧乳腺切除+前哨淋巴结活检术",
              totalCost:"16000.00-20000.00",
              selfPay:"4800~6000",
            },
            {
              name:"双侧乳腺切除+前哨淋巴结活检术",
              totalCost:"25000.00-30000.00",
              selfPay:"7500~9000",
            },
            {
              name:"单侧乳腺切除+前哨淋巴结活检术+腋窝淋巴结清扫术",
              totalCost:"18000.00-21000.00",
              selfPay:"5400~6300",
            },
            {
              name:"双侧乳腺切除+前哨淋巴结活检术+腋窝淋巴结清扫术",
              totalCost:"26000.00-31000.00",
              selfPay:"7800~9300",
            },
            {
              name:"单侧乳腺切除+腋窝淋巴结清扫术",
              totalCost:"17000.00-20000.00",
              selfPay:"5100~6000",
            },
            {
              name:"双侧乳腺切除+腋窝淋巴结清扫术",
              totalCost:"22000.00-28000.00",
              selfPay:"6600~8400",
            }
          ]
        }
      ],
      shuhouxinfuzhu:[
        {
          code:"01",
          name:"三阴性乳腺癌辅助化疗",
          totalCost:"1.5万-5.1万",
          selfPay:"0.6万-2.2万",
          children:[
            {
              name:"蒽环类+环磷酰胺序贯多西他赛",
              totalCost:"50940.00",
              selfPay:"21859.20",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*4",
                  price:"155.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*4",
                  price:"995.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"密集型蒽环类+环磷酰胺序贯密集型紫杉醇",
              totalCost:"24960.00",
              selfPay:"8460.00",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*4",
                  price:"155.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
                {
                  url:zhanshanchunzhusheye,
                  drugs:"紫杉醇注射液",
                  course:"1/21d*4",
                  price:"246.00",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"蒽环类+环磷酰胺",
              totalCost:"15120.00",
              selfPay:"6098.40",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*4",
                  price:"155.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"多西他赛+环磷酰胺",
              totalCost:"31820.00",
              selfPay:"13446.40",
              children:[
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*4",
                  price:"995.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
              ]
            }
          ]
        },
        {
          code:"02",
          name:"蒽环类+环磷酰胺序贯多西他赛",
          totalCost:"1.5万-5.1万",
          selfPay:"0.6万-2.2万",
          children:[
            {
              name:"蒽环类+环磷酰胺序贯多西他赛",
              totalCost:"50940.00",
              selfPay:"21859.20",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*4",
                  price:"155.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*4",
                  price:"995.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"密集型蒽环类+环磷酰胺序贯密集型紫杉醇",
              totalCost:"24960.00",
              selfPay:"8460.00",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*4",
                  price:"155.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
                {
                  url:zhanshanchunzhusheye,
                  drugs:"紫杉醇注射液",
                  course:"1/21d*4",
                  price:"246.00",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"蒽环类+环磷酰胺",
              totalCost:"15120.00",
              selfPay:"6098.40",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*4",
                  price:"155.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"多西他赛+环磷酰胺",
              totalCost:"31820.00",
              selfPay:"13446.40",
              children:[
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*4",
                  price:"995.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
              ]
            }
          ]
        },
        {
          code:"03",
          name:"激素受体阳性乳腺癌辅助内分泌治疗（绝经前）",
          totalCost:"0.8万-8.3万",
          selfPay:"0.2万-7.9万",
          children:[
            {
              name:"枸橼酸他莫昔芬片",
              totalCost:"8139.50",
              selfPay:"2441.85",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"bid*5年",
                  price:"2.23",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"卵巢功能抑制+他莫昔芬",
              totalCost:"85139.50",
              selfPay:"79441.85",
              children:[
                {
                  url:gesheruilin,
                  drugs:"戈舍瑞林",
                  course:"5年",
                  price:"3500.00",
                  medical:"丙类"
                },
                {
                  url:juyuansuantamoxifenpian,
                  drugs:"枸橼酸他莫昔芬片",
                  course:"bid*5年",
                  price:"2.23",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"卵巢功能抑制+芳香化酶抑制剂",
              totalCost:"83077.25",
              selfPay:"79673.99",
              children:[
                {
                  url:gesheruilin,
                  drugs:"戈舍瑞林",
                  course:"5年",
                  price:"3500.00",
                  medical:"丙类"
                },
                {
                  url:laiquzupian,
                  drugs:"来曲唑片",
                  course:"qd*5年",
                  price:"3.33",
                  medical:"乙类"
                },
              ]
            },
          ]
        },
        {
          code:"04",
          name:"激素受体阳性乳腺癌辅助内分泌治疗（绝经后）",
          totalCost:"0.6万-2.3万",
          selfPay:"0.2万-1万",
          children:[
            {
              name:"芳香化酶抑制剂",
              totalCost:"6077.25",
              selfPay:"2673.99",
              children:[
                {
                  url:laiquzupian,
                  drugs:"来曲唑片",
                  course:"qd*5年",
                  price:"3.33",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"芳香化酶抑制剂",
              totalCost:"11552.25",
              selfPay:"5082.99 ",
              children:[
                {
                  url:anaquzuopian,
                  drugs:"阿那曲唑片",
                  course:"qd*5年",
                  price:"6.33",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"芳香化酶抑制剂",
              totalCost:"23725.00",
              selfPay:"10439.00",
              children:[
                {
                  url:yiximeitanpian,
                  drugs:"依西美坦片",
                  course:"5年",
                  price:"13.00",
                  medical:"乙类"
                },
              ]
            },
          ]
        },
        {
          code:"05",
          name:"HER2阳性乳腺癌辅助治疗（未行新辅助治疗）",
          totalCost:"1.6万-8.4万",
          selfPay:"0.7万-5万",
          children:[
            {
              name:"蒽环类+环磷酰胺序贯紫杉类+曲妥珠单抗+帕妥珠单抗",
              totalCost:"56680.00",
              selfPay:"28422.40",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*4",
                  price:"155.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
                {
                  url:zhanshanchunzhusheye,
                  drugs:"紫杉醇注射液",
                  course:"1/7d*12",
                  price:"246.00",
                  medical:"甲类"
                },
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:patuozhudankang,
                  drugs:"帕妥珠单抗",
                  course:"1/21d*4",
                  price:"2180.00",
                  medical:"丙类"
                },
              ]
            },
            {
              name:"多西他赛+卡铂+曲妥珠单抗+帕妥珠单抗",
              totalCost:"84110.00",
              selfPay:"44436.80",
              children:[
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*6",
                  price:"995.00",
                  medical:"乙类"
                },
                {
                  url:kabo,
                  drugs:"卡铂",
                  course:"1/21d*6",
                  price:"95.00",
                  medical:"甲类"
                },
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*6",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:patuozhudankang,
                  drugs:"帕妥珠单抗",
                  course:"1/21d*6",
                  price:"2180.00",
                  medical:"丙类"
                },
              ]
            },
            {
              name:"蒽环类+环磷酰胺序贯紫杉类+曲妥珠单抗",
              totalCost:"45780.00",
              selfPay:"17522.40",
              children:[
                {
                  url:biaoroubixing,
                  drugs:"表柔比星",
                  course:"1/21d*4",
                  price:"155.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
                {
                  url:zhanshanchunzhusheye,
                  drugs:"紫杉醇注射液",
                  course:"1/21d*4",
                  price:"246.00",
                  medical:"甲类"
                },
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"多西他赛+卡铂+曲妥珠单抗",
              totalCost:"68850.00",
              selfPay:"29176.80",
              children:[
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*6",
                  price:"995.00",
                  medical:"乙类"
                },
                {
                  url:kabo,
                  drugs:"卡铂",
                  course:"1/21d*6",
                  price:"95.00",
                  medical:"甲类"
                },
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*6",
                  price:"3180.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"多西他赛+环磷酰胺+曲妥珠单抗",
              totalCost:"47720.00",
              selfPay:"20442.40",
              children:[
                {
                  url:duoxitasai,
                  drugs:"多西他赛",
                  course:"1/21d*4",
                  price:"995.00",
                  medical:"乙类"
                },
                {
                  url:huanlinxian,
                  drugs:"环磷酰胺",
                  course:"1/21d*4",
                  price:"90.00",
                  medical:"甲类"
                },
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*6",
                  price:"3180.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗+芳香化酶抑制剂",
              totalCost:"16431.72",
              selfPay:"7229.96",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:anaquzuopian,
                  drugs:"阿那曲唑片",
                  course:"qd*21d*4",
                  price:"6.33",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗+芳香化酶抑制剂",
              totalCost:"16992.00",
              selfPay:"7476.48",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:yiximeitanpian,
                  drugs:"依西美坦片",
                  course:"qd*21d*4",
                  price:"13.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗+芳香化酶抑制剂",
              totalCost:"16179.72",
              selfPay:"7119.08",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:laiquzupian,
                  drugs:"来曲唑片",
                  course:"qd*21d*4",
                  price:"3.33",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗+他莫昔芬",
              totalCost:"16274.64",
              selfPay:"7108.39",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:juyuansuantamoxifenpian,
                  drugs:"枸橼酸他莫昔芬片",
                  course:"qd*21d*4",
                  price:"2.23",
                  medical:"甲类"
                },
              ]
            },
            {
              name:"曲妥珠单抗+氟维司群",
              totalCost:"33298.80",
              selfPay:"14651.47",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:fuweisiqunzhusheye,
                  drugs:"氟维司群注射液",
                  course:"1/28d*4",
                  price:"2899.80",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗+CDK 4/6 抑制剂",
              totalCost:"59699.49",
              selfPay:"50795.49",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:paibaixilijiaonang,
                  drugs:"哌柏西利胶囊",
                  course:"qd*21d*3",
                  price:"695.23",
                  medical:"丙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗+HDAC 抑制剂",
              totalCost:"59699.49",
              selfPay:"50795.49",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:xidanbenan,
                  drugs:"西达本胺",
                  course:"qd*6d*4",
                  price:"144.12",
                  medical:"丙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗+孕激素",
              totalCost:"59699.49",
              selfPay:"50795.49",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:jiaqiangyuntong,
                  drugs:"甲羟孕酮",
                  course:"bid*21d*4",
                  price:"25.50",
                  medical:"甲类"
                },
              ]
            },
          ]
        },
        {
          code:"06",
          name:"HER2阳性乳腺癌辅助治疗（术前使用新辅助治疗）",
          totalCost:"1.6万-7.2万",
          selfPay:"0.7万-7.2万",
          children:[
            {
              name:"曲妥珠单抗",
              totalCost:"15900.00",
              selfPay:"6996.00",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗 + 帕妥珠单抗",
              totalCost:"26800.00",
              selfPay:"17896.00",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:patuozhudankang,
                  drugs:"帕妥珠单抗",
                  course:"1/21d*4",
                  price:"2180.00",
                  medical:"丙类"
                },
              ]
            },
            {
              name:"恩美曲妥珠单抗",
              totalCost:"72289.80",
              selfPay:"72289.80",
              children:[
                {
                  url:enmeiqutuozhudankang,
                  drugs:"恩美曲妥珠单抗",
                  course:"1/21d*4",
                  price:"14604.00",
                  medical:"丙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗 + 帕妥珠单抗",
              totalCost:"26800.00",
              selfPay:"17896.00",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:patuozhudankang,
                  drugs:"帕妥珠单抗",
                  course:"1/21d*4",
                  price:"2180.00",
                  medical:"丙类"
                },
              ]
            },
            {
              name:"恩美曲妥珠单抗",
              totalCost:"72289.80",
              selfPay:"72289.80",
              children:[
                {
                  url:enmeiqutuozhudankang,
                  drugs:"恩美曲妥珠单抗",
                  course:"1/21d*4",
                  price:"14604.00",
                  medical:"丙类"
                },
              ]
            },
            {
              name:"曲妥珠单抗 + 帕妥珠单抗",
              totalCost:"726800.00",
              selfPay:"17896.00",
              children:[
                {
                  url:qutuokangzhu,
                  drugs:"曲妥珠单抗",
                  course:"1/21d*4",
                  price:"3180.00",
                  medical:"乙类"
                },
                {
                  url:patuozhudankang,
                  drugs:"帕妥珠单抗",
                  course:"1/21d*4",
                  price:"2180.00",
                  medical:"丙类"
                },
              ]
            },
          ]
        },
      ],
      shuhoufangliao:[
        {
          code:"01",
          name:"保乳术后",
          totalCost:"1200元-3500元",
          selfPay:"360元-1050元",
          children:[
            {
              name:"全乳放疗（常规疗程或大分割）+-瘤床加量（2B）",
              totalCost:"1200-3500",
              selfPay:"360-1050",
            },
            {
              name:"全乳放疗（常规疗程或大分割）+-瘤床加量（1B）",
              totalCost:"1200-3500",
              selfPay:"360-1050",
            },
            {
              name:"全乳放疗+瘤床加量+区域淋巴结放疗",
              totalCost:"1200-3500",
              selfPay:"360-1050",
            },
            {
              name:"全乳（乳房高位切线野）+瘤床加量（1A）",
              totalCost:"1200-3500",
              selfPay:"360-1050",
            },
            {
              name:"全乳放疗+瘤床加量+腋窝淋巴结放疗",
              totalCost:"1200-3500",
              selfPay:"360-1050",
            }
          ]
        },
        {
          code:"02",
          name:"乳房切除术后",
          totalCost:"1200元-3500元",
          selfPay:"360元-1050元",
          children:[
            {
              name:"胸壁+区域淋巴结放疗（1-2A）",
              totalCost:"1200-3500",
              selfPay:"360-1050",
            },
            {
              name:"胸壁+包括腋窝的区域淋巴结放疗（2B）",
              totalCost:"1200-3500",
              selfPay:"360-1050",
            },
          ]
        }
      ],
      active:"1"
    }
  },
  components: {
    programme
  },
  created() {

  },
  mounted() {
    this.getSessionState()
  },
  methods: {
    getSessionState(){
      sessionState(this.$store.getters.session).then((res)=>{
        console.log(res)
          this.BIRADS = res.bi_rads
      })},
    onchange(number){
      this.active = number
    },
    getBIRADS(vul){
      if(vul=='0级'){
        return 'BI-RADS 0'
      }
      if(vul=='1级'){
        return 'BI-RADS 1'
      }
      if(vul=='2级'){
        return 'BI-RADS 2'
      }
      if(vul=='3级'){
        return 'BI-RADS 3'
      }
      if(vul=='4a级'){
        return 'BI-RADS 4a'
      }
      if(vul=='4b级'){
        return 'BI-RADS 4b'
      }
      if(vul=='4c级'){
        return 'BI-RADS 4c'
      }
      if(vul=='5级'){
        return 'BI-RADS 5'
      }
      if(vul=='6级'){
        return 'BI-RADS 6'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.knowledge{
  width: 100%;
  height: 100%;
  background: url('../../assets/images/bg.jpg');
  background-size:100% 100% ;
  position: fixed;
  overflow-y: scroll;
  padding: .3rem;
  .wenti-img{
    width: 100%;
    height: .34rem;
    img{
      width:.4rem;
      height: .34rem;
    }
  }
  .bottom{
    margin-top: .3rem;
    .knowledge-box{
      background: #FFFFFF;
      box-shadow: 0px -1px 9px 0px rgba(111, 210, 255, 0.09);
      border-radius: 8px;
      padding: .4rem .3rem;
      .title{
        color: #131313;;
        font-size: .28rem;
      }
      .tijian{
        font-size: .24rem;
        line-height: .48rem;
        color: rgba(19, 19, 19, .5);
      }
      .mianze{
        .tips{
          margin: .22rem 0;
        }
        font-size: .24rem;
        color: #1675FF;
        border-bottom: 1px solid rgba(146, 157, 163, .2);
        padding-bottom: .3rem;
      }
      .yuhou{
        .tips{
          font-weight: 500;
          color: #131313;
          font-size: .28rem;
          margin: .3rem 0;
        }
        .center{
          width: 100%;
          height: 1.16rem;
          background: url("../../assets/images/tgbg.png") no-repeat;
          background-size: 100% 100%;
          color: #fff;
          font-weight: 500;
          font-size: .24rem;
          padding: .1rem;
          line-height: .5rem;
        }
        .list-box{
          .tips{
            color: rgba(76, 76, 76, .5);
            font-size: .24rem;
            line-height: .48rem;
            margin: .2rem 0;
          }
          .list{
            font-size: .24rem;
            li{
              line-height: .48rem;
              list-style-type:disc;
              margin-left: .3rem;
              span{
                color: #1675FF;
                display: inline-block;
              }
            }
          }
        }
      }
      .center{
        font-size: .26rem;
        line-height: .5rem;
        margin-top: .4rem;
      }
    }
  }
}
.programme-title{
  font-size: .28rem;
  font-weight: bold;
  color: #131313;
}
.programme-top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: .3rem;
  margin-top: .4rem;
  div{
    background: #C4C4C4;
    border-radius: .1rem;
    text-align: center;
    font-size: .24rem;
    color: #FFFFFF;
    padding: .1rem .1rem;
  }
  .active{
    background: #1675FF;
  }
}
.programme-box-title{
  color: #1675FF;
  font-size: .24rem;
  img{
    float: left;
    width: .29rem;
    height: .29rem;
    margin-right: .1rem;
    margin-bottom: .4rem;
    margin-top: .1rem;
    span{
      margin-top: .18rem;
    }
  }
}
.plan-title{
  display: flex;
  font-size: .24rem;
  margin-top: .3rem;
}
.plan-title-center{
  color: #FF1B16;
  span{
    color: #666666;
    margin-right: .3rem;
    margin-top: .19rem;
  }
}
.drugs-list{
  margin-top: .3rem;
  img{
    width: 2.05rem;
    height: 1.48rem;
    float: left;
    margin-right: .36rem;
    border-radius: .1rem;
  }
  font-size: .24rem;
  color: #131313;
  line-height: .45rem;
}
.programme-center{
  display: flex;
}
.layer-box{
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.5);
  z-index: 9999;
  .layer{
    width: 90%;
    margin: 1.5rem auto .5rem auto;
    background: #fff;
    padding: .3rem;
    border-radius: .2rem;
  }
}
</style>

